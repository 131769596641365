import BaseService from '@/services/base.service';
import { dataURLtoFile } from '@/common/util';
class ParametrosService extends BaseService {
    constructor() {
        super({ resource: 'parametro' });
    }
    async update(data) {
        return await this.plantarApi.put(this.resource, data);
    }
    async dataUrlUpload(data, fileName) {
        const formData = new FormData();
        formData.append('arquivo', typeof data === 'string' ? dataURLtoFile(data, fileName) : data);
        return await this.plantarApi.post(`${this.resource}/AdicionarArquivo`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });
    }
    async deleteImg(url) {
        return await this.plantarApi.delete(`${this.resource}/RemoverArquivo/${url}`);
    }
    async clientSettings(opt) {
        const { subDomain, clientUrl } = opt;
        const postData = { subDomain };
        if (clientUrl)
            postData.clientUrl = clientUrl;
        const { success, data } = await this.plantarApi.post(`open/parametros`, postData, {
            headers: {
                'X-Tenant': '00000000-0000-0000-0000-000000000000'
            }
        });
        return {
            success,
            data
        };
    }
}
const parametrosService = new ParametrosService();
export default parametrosService;
