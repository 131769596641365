import { defineStore } from 'pinia';
import { reactive, ref, computed } from 'vue';
import { vistraService } from '@/services/vistra.service';
import webStorage from '@/common/webStorage';
import { dialog, i18N } from '@/common/util';
import biService from '@/services/bi.service';
export const useVistraStore = defineStore('vistra', () => {
    const list = ref([]);
    const status = reactive({
        getAll: 'initial',
        create: 'initial',
        update: 'initial',
        delete: 'initial',
        getById: 'initial',
        loginVistra: 'initial',
        getPanel: 'initial'
    });
    const loading = computed(() => Object.values(status).some(el => el === 'loading'));
    const vistraToken = ref(webStorage.getItem('vistra-token'));
    const vistraRefreshToken = ref(webStorage.getItem('vistra-refershtoken'));
    // Endpoints Plantar
    const getAll = async () => {
        status.getAll = 'loading';
        try {
            const result = await vistraService.get();
            list.value = result.data.map(el => formatProps(el, true));
            status.getAll = 'success';
            return result;
        }
        catch (error) {
            status.getAll = 'error';
        }
    };
    const create = async (obj) => {
        status.create = 'loading';
        try {
            const { data, success } = await vistraService.post(null, formatProps(obj));
            if (success)
                getAll();
            status.create = 'success';
            return data;
        }
        catch (error) {
            status.create = 'error';
        }
    };
    const update = async (id, obj) => {
        status.update = 'loading';
        try {
            const { data, success } = await vistraService.put(id, formatProps(obj));
            if (success)
                getAll();
            status.update = 'success';
            return data;
        }
        catch (error) {
            status.update = 'error';
        }
    };
    const remove = async (obj, force) => {
        if (!force)
            return dialog.open({
                title: i18N('feedback.attention'),
                message: i18N('dialogs.delete.message', { type: i18N('menu.vistraPanels').toLowerCase(), name: obj.name }),
                complete: () => remove(obj, true)
            });
        try {
            status.delete = 'loading';
            const { success } = await vistraService.delete(obj.id);
            if (success)
                getAll();
            status.delete = 'success';
            return success;
        }
        catch {
            status.delete = 'error';
        }
    };
    const getById = async (id) => {
        status.getById = 'loading';
        try {
            const { data } = await vistraService.get(id);
            status.getById = 'success';
            return formatProps(data, true);
        }
        catch (error) {
            status.getById = 'error';
        }
    };
    const formatProps = (obj, isResponse = false) => {
        return (isResponse
            ? {
                ...(obj.id ? { id: obj.id } : {}),
                name: obj.painelNome,
                code: obj.painelId,
                public: obj.publico,
                contracts: obj.contratosIds,
                active: obj.ativo,
                parameters: obj.parametros
            } : {
            ...(obj.id ? { id: obj.id } : {}),
            painelNome: obj.name,
            painelId: obj.code,
            publico: obj.public,
            contratosIds: obj.contracts,
            ativo: obj.active,
            parametros: obj.parameters
        });
    };
    // Endpoints Vistra
    const loginVistra = async () => {
        status.loginVistra = 'loading';
        try {
            webStorage.removeItem('vistra-token');
            vistraToken.value = '';
            const { data } = await biService.login({
                email: 'plantar',
                password: 'adm#Pl@ntar;4'
            });
            if (data?.authToken) {
                webStorage.setItem('vistra-token', data.authToken, true);
                webStorage.setItem('vistra-refershtoken', data.refreshToken, true);
                vistraToken.value = data.authToken;
                vistraRefreshToken.value = data.refreshToken;
            }
            status.loginVistra = 'success';
            return true;
        }
        catch (error) {
            status.loginVistra = 'error';
        }
    };
    return {
        // States Plantar
        list,
        status,
        // Actions Plantar
        getAll,
        create,
        update,
        remove,
        getById,
        // States Vistra
        vistraToken,
        vistraRefreshToken,
        // Actions Vistra
        loginVistra,
        //Getters
        loading
    };
});
