import { reactive, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { defineStore } from 'pinia';
import { useAuthStore } from './authStore';
import secaoService from '@/services/secao.service';
export const useSecoesStore = defineStore('secoes', () => {
    const { t } = useI18n();
    const sectionsLearning = ref([]);
    const sectionsCollection = ref([]);
    const itemsCount = reactive({ aprendizado: 0, colecao: 0 });
    const paginationItems = ref([]);
    const status = reactive({
        getSections: 'initial',
        getSectionItems: [],
        getSecaoById: 'initial',
        learn: 'initial',
        collection: 'initial',
        getFavoriteItems: 'initial',
        basicList: {
            all: 'initial',
            files: 'initial',
            courses: 'initial',
            trails: 'initial',
            assessments: 'initial'
        }
    });
    const basicList = ref({
        files: [],
        courses: [],
        trails: [],
        assessments: []
    });
    const lazyBasicList = computed(() => {
        return (type) => {
            if (!type)
                type = 'all';
            if (status.basicList[type] === 'initial') {
                getBasicList({ origem: 'Proprio', type: type });
                return [{ value: '', label: t('feedback.wait') }];
            }
            return basicList.value[type];
        };
    });
    const loadingBasicList = computed(() => Object.values(status.basicList).some(value => value === 'loading'));
    const listFavorite = ref({
        status: false,
        data: [],
        page: 0,
        size: 0,
        pages: 0,
        totalITems: 0
    });
    /**
     * Pool de seções para evitar chamadas desnecessárias
     */
    const totalPages = ref({
        learning: 0,
        collection: 0,
        favorites: 0
    });
    const sectionsPool = ref([]);
    /**
     * Pool de items de seções
     */
    const sectionItemsPool = ref([]);
    /**
     * Seções carregadas com sucesso
     * @param type Tipo de seção
     */
    const getLoadedSection = (type) => {
        let lastPage = 0;
        const pages = sectionsPool.value
            .filter((el) => el.type === type)
            .map((el) => {
            lastPage = el.page > lastPage ? el.page : lastPage;
            return el;
        });
        return {
            pages,
            lastPage,
            totalPages: totalPages.value[type],
            status: (pages.find(el => el.status === 'loading' || el.status === 'loadingMore')?.status || '')
        };
    };
    const poolSectionItems = computed(() => (idSection) => {
        let lastPage = 0;
        let totalPages = 0;
        const pages = sectionItemsPool.value
            .filter((el) => el.idSection === idSection)
            .map((el) => {
            lastPage = el.page > lastPage ? el.page : lastPage;
            totalPages = el.nPages > totalPages ? el.nPages : totalPages;
            return el;
        });
        return {
            pages,
            lastPage,
            totalPages,
            loading: !!pages.find(el => el.status === 'loading')
        };
    });
    const loadingMoreSections = computed(() => status.getSections === 'loading');
    const isLoading = computed(() => (idSection) => status.getSectionItems.find((el) => el.idSection === idSection)?.status === 'loading');
    const hasMoreItems = computed(() => (idSection, environment) => {
        const pItems = paginationItems.value.find((el) => el.idSection === idSection)?.totalItems;
        const sItems = status.getSectionItems.find((el) => el.idSection === idSection);
        const list = environment === 'learning' ? sectionsLearning.value : sectionsCollection.value;
        return list.find((el) => el.id === idSection)?.todosOsItens?.length < pItems || !sItems;
    });
    const getSecaoById = async (id, params) => {
        status.getSecaoById = 'loading';
        const filter = removeEmptyProperties(params);
        const page = params?.page || 1;
        const size = params?.size || 12;
        const requestParams = {
            ...filter,
            page,
            size
        };
        try {
            const response = await secaoService.getActivesItemsPaged(id, requestParams);
            status.getSecaoById = 'success';
            return {
                status: response.success,
                data: response.data || [],
                page: response.pageNumber,
                size: response.pageSize,
                pages: response.totalPages,
                totalITems: response.totalItems
            };
        }
        catch {
            status.getSecaoById = 'error';
        }
        return {
            status: false,
            data: [],
            page: 0,
            size: 0,
            pages: 0,
            totalITems: 0
        };
    };
    const loadSectionsToPool = async (params) => {
        // Verifica o pool de seções
        const page = params?.page || 1;
        let sectionList = sectionsPool.value.find((el) => el.type === params.environment && el.page === page);
        // Se não existir, cria uma nova entrada de paginação
        if (!sectionList) {
            sectionList = {
                type: params.environment,
                page: params?.page || 1,
                status: 'loading',
                lastUpdated: performance.now(),
                data: []
            };
            sectionsPool.value.push(sectionList);
            sectionList = sectionsPool.value.find((el) => el.type === params.environment && el.page === page);
        }
        else {
            sectionList.status = (totalPages.value[params.environment] > 1) ? 'loading' : 'loadingMore';
        }
        try {
            const response = await secaoService.findActive({ ...params, size: params?.size || 2 });
            if (response.success) {
                // Se for a primeira página de "learning", carrega também "Continue" e "Suggested"
                if (params.environment === 'learning' && page === 1) {
                    if (sectionList.data.findIndex((el) => el.id === 'continue') < 0) {
                        sectionList.data.push({
                            id: 'continue',
                            type: 'content',
                            environment: 'learning',
                            name: t('mainContent.sections.keepLearning'),
                            observation: ''
                        });
                    }
                    if (sectionList.data.findIndex((el) => el.id === 'suggested') < 0) {
                        sectionList.data.push({
                            id: 'suggested',
                            type: 'content',
                            environment: 'learning',
                            name: t('mainContent.sections.suggestedForYou'),
                            observation: ''
                        });
                    }
                    ['continue', 'suggested'].forEach((id) => {
                        getSectionItems(id, { ...params, page: 1 }).then((e) => {
                            // Se não encontrar nenhum item, remove a seção
                            if (e && e.data?.length === 0) {
                                sectionList.data.splice(sectionList.data.findIndex((el) => el.id === id), 1);
                            }
                        });
                    });
                }
                // Atualiza o total de páginas do ambiente
                totalPages.value[params.environment] = response.totalPages;
                // Aguarda todas as requisições de itens serem finalizadas
                const promiseList = [];
                // Para cada seção retornada, carrega seus primeiros itens
                response.data.forEach((el) => {
                    // se ja existir (baseado no ID), atualiza
                    const idx = sectionList.data.findIndex((s) => s.id === el.id);
                    if (idx >= 0)
                        sectionList.data.splice(idx, 1, el);
                    else
                        sectionList.data.push(el);
                    promiseList.push(getSectionItems(el.id, { ...params, page: 1 }));
                });
                await Promise.all(promiseList);
                sectionList.status = 'success';
            }
            else {
                sectionList.status = 'error';
            }
        }
        catch (e) {
            sectionList.status = 'error';
        }
        sectionList.lastUpdated = performance.now();
    };
    const emptySectionsPool = () => {
        sectionsPool.value.splice(0);
        sectionItemsPool.value.splice(0);
    };
    const getSectionItems = async (idSection, params) => {
        // Verifica o pool de seções
        const page = params?.page || 1;
        const size = params?.size || 6;
        let sectionItems = sectionItemsPool.value.find((el) => el.idSection === idSection && el.page === page);
        if (!sectionItems) {
            sectionItems = {
                idSection,
                page,
                nPages: 0,
                status: 'loading',
                lastUpdated: performance.now(),
                data: []
            };
            sectionItemsPool.value.push(sectionItems);
            sectionItems = sectionItemsPool.value.find((el) => el.idSection === idSection && el.page === page);
        }
        else {
            sectionItems.status = 'loading';
        }
        return secaoService
            .findSectionItems(idSection, { ...params, size })
            .then((res) => {
            if (res.success) {
                sectionItems.data = res.data;
                sectionItems.nPages = res.nPages;
            }
            sectionItems.status = res.success ? 'success' : 'error';
            return res;
        })
            .catch(() => {
            sectionItems.status = 'error';
        })
            .finally(() => {
            sectionItems.lastUpdated = performance.now();
        });
    };
    const getFilter = (filters) => ({
        tipoAmbiente: filters.environment ? (filters.environment === 'learning' ? 'Aprendizado' : 'Colecao') : null,
        tiposConteudos: filters.contents,
        categoriasIds: filters.categories,
        competenciasIds: filters.skills,
        secoesIds: filters.sections,
        obrigatorio: filters.required,
        search: filters.search,
        status: filters.status,
        origem: filters.origin
    });
    const removeEmptyProperties = (filter) => {
        return Object.fromEntries(Object.entries(filter).filter(([, value]) => value?.length || [true, false].includes(value)));
    };
    const getBasicList = (params) => {
        if (!params)
            params = {};
        if (!params?.type)
            params.type = 'all';
        status.basicList[params.type] = 'loading';
        return secaoService.getListBasic({ ...params })
            .then(({ data }) => {
            basicList.value[params.type] = (data || []).map(c => ({ value: c.id, label: c.nome }));
            status.basicList[params.type] = 'success';
            return basicList.value;
        })
            .catch(() => { status.basicList[params.type] = 'error'; });
    };
    const getCountActivesItems = async (params) => {
        try {
            const filter = removeEmptyProperties(getFilter(params));
            delete filter.tipoAmbiente;
            const { data, success } = await secaoService.getCountActivesItems({ ...filter });
            if (success)
                Object.assign(itemsCount, data);
        }
        catch {
            //
        }
    };
    let holdHasNoSections = false;
    /**
     * Método "inteligente" de verificar se existe ao menos uma seção ativa para o tenant atual
     */
    const hasNoSections = async () => {
        if (holdHasNoSections)
            return false; // Ignora enquanto uma requisicao estiver em andamento
        const sss = (sessionStorage.getItem('tSess') || '').split(',').filter((el) => el);
        if (sss.includes(useAuthStore().getActiveTentant))
            return false;
        try {
            holdHasNoSections = true;
            const { items } = await secaoService.findActive({ page: 1, size: 1 });
            if (items > 0) {
                sss.push(useAuthStore().getActiveTentant);
                sessionStorage.setItem('tSess', sss.join(','));
                return false;
            }
        }
        catch (error) {
            //
        }
        finally {
            holdHasNoSections = false;
        }
        return true;
    };
    const emptyNoSections = () => {
        sessionStorage.removeItem('tSess');
    };
    const updateMedia = ({ itemId, subscribed, progress }) => {
        // Go through every section and update the item
        sectionItemsPool.value.forEach((section) => {
            const item = section.data.find((el) => el.id === itemId);
            if (item) {
                if ([false, true].includes(subscribed))
                    item.inscrito = subscribed;
                if (Number.isInteger(progress))
                    item.porcentagemDeConclusao = progress;
                item._refresh = item?._refresh ? (item._refresh + 1) : 1;
            }
        });
    };
    const updateSectionItem = (itemId, obj) => {
        // Go through every section and update the item
        sectionItemsPool.value.forEach((section) => {
            const item = section.data.find((el) => el.id === itemId);
            if (item) {
                Object.assign(item, obj);
                item._refresh = item?._refresh ? (item._refresh + 1) : 1;
            }
        });
    };
    const emptyList = () => {
        Object.keys(status.basicList).forEach((key) => status.basicList[key] = 'initial');
        Object.keys(basicList.value).forEach((key) => basicList.value[key].splice(0));
    };
    const reset = () => {
        sectionsLearning.value.splice(0);
        sectionsCollection.value.splice(0);
        itemsCount.aprendizado = 0;
        itemsCount.colecao = 0;
        paginationItems.value.splice(0);
        status.getSections = 'initial';
        status.getSectionItems.splice(0);
        status.getSecaoById = 'initial';
        status.learn = 'initial';
        status.collection = 'initial';
        emptyList();
        emptySectionsPool();
    };
    const getFavoriteItems = async () => {
        status.getFavoriteItems = 'loading';
        try {
            const response = await secaoService.getFavorites();
            listFavorite.value = {
                status: response.success,
                data: response.data || [],
                page: response.page,
                size: response.size,
                pages: response.pages,
                totalITems: response.totalItems
            };
            status.getFavoriteItems = 'success';
            return listFavorite.value;
        }
        catch {
            status.getFavoriteItems = 'error';
        }
    };
    return {
        // states
        sectionsPool,
        status,
        basicList,
        itemsCount,
        sectionsLearning,
        sectionsCollection,
        listFavorite,
        sectionItemsPool,
        // Getters
        poolSectionItems,
        hasMoreItems,
        loadingMoreSections,
        isLoading,
        lazyBasicList,
        loadingBasicList,
        // Actions
        getLoadedSection,
        getSectionItems,
        loadSectionsToPool,
        emptySectionsPool,
        getBasicList,
        getListBasic: getBasicList,
        getSecaoById,
        getCountActivesItems,
        hasNoSections,
        emptyNoSections,
        updateMedia,
        reset,
        getFavoriteItems,
        updateSectionItem
    };
});
export default useSecoesStore;
