import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderSlot as _renderSlot, mergeProps as _mergeProps } from "vue";
const _hoisted_1 = ["disabled"];
const _hoisted_2 = {
    key: 0,
    class: "flex items-center justify-center gap-1"
};
const _hoisted_3 = {
    key: 1,
    class: "flex items-center justify-center gap-1"
};
import { computed } from 'vue';
import UiIcon from './icon.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'button',
    props: {
        type: {},
        round: { type: Boolean },
        rounded: { type: Boolean },
        disabled: { type: Boolean },
        loading: { type: Boolean },
        loadingText: {},
        size: {},
        icon: {},
        iconSize: {},
        outline: { type: Boolean },
        text: {}
    },
    setup(__props) {
        const props = __props;
        const buttonClass = computed(() => {
            const classes = [];
            if (['primary', 'info', 'success', 'warning', 'danger', 'ghost', 'link'].includes(props.type))
                classes.push(props.type);
            if (props.outline)
                classes.push('uib-outline');
            if (props.round)
                classes.push('aspect-square !rounded-full');
            if (props.rounded)
                classes.push('!rounded-full');
            return classes;
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("button", _mergeProps({
                type: "button",
                disabled: (_ctx.disabled || _ctx.loading),
                class: ["ui-button", buttonClass.value]
            }, _ctx.$attrs), [
                (_ctx.loading)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                        _createVNode(UiIcon, {
                            class: "animate-spin",
                            icon: "loader",
                            size: _ctx.iconSize
                        }, null, 8, ["size"]),
                        _createTextVNode(" " + _toDisplayString(_ctx.loadingText || _ctx.$t('feedback.await')), 1)
                    ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        (_ctx.icon)
                            ? (_openBlock(), _createBlock(UiIcon, {
                                key: 0,
                                icon: _ctx.icon,
                                size: _ctx.iconSize
                            }, null, 8, ["icon", "size"]))
                            : _createCommentVNode("", true),
                        _renderSlot(_ctx.$slots, "default", {}, () => [
                            _createTextVNode(_toDisplayString(_ctx.text), 1)
                        ])
                    ]))
            ], 16, _hoisted_1));
        };
    }
});
