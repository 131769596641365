import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, unref as _unref, withCtx as _withCtx, renderSlot as _renderSlot, Transition as _Transition, Teleport as _Teleport } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "flex items-center gap-3 px-4 py-[10px]"
};
const _hoisted_2 = {
    key: 0,
    class: "absolute left-0 h-6 w-0.5 rounded-sm bg-[--c-primary-900] dark:bg-[--c-primary-200]"
};
const _hoisted_3 = { class: "grow" };
const _hoisted_4 = {
    key: 1,
    class: "flex h-10 w-10 items-center justify-center"
};
const _hoisted_5 = {
    key: 0,
    class: "absolute left-0 h-6 w-0.5 rounded-sm bg-[--c-primary-900] dark:bg-[--c-primary-200]"
};
const _hoisted_6 = {
    key: 0,
    class: "ml-7 flex flex-col gap-2 border-0 border-l border-solid border-neutral-200 p-0"
};
const _hoisted_7 = {
    key: 0,
    class: "fixed z-10 inset-0 left-20 w-64 border-0 border-r border-solid border-neutral-200 bg-neutral-50 dark:bg-[#2e2e2e] dark:border-neutral-600"
};
import { ref, inject, computed, onMounted } from 'vue';
import { RouterLink } from 'vue-router';
import { OnClickOutside } from '@vueuse/components';
import UiIcon from '@/components/ui/icon.vue';
import autoAnimate from '@formkit/auto-animate';
export default /*@__PURE__*/ _defineComponent({
    __name: 'sidebarItem',
    props: {
        active: { type: Boolean },
        icon: {},
        name: {},
        items: {},
        to: {},
        allowMultiple: { type: Boolean },
        minimized: { type: Boolean },
        redirect: {}
    },
    setup(__props) {
        const header = ref();
        const props = __props;
        const open = ref(false);
        const openItems = inject('openItems', ref([]));
        const isOpen = computed(() => openItems.value.includes(props.name));
        const toggle = () => {
            if (props.allowMultiple)
                openItems.value = !isOpen.value
                    ? [...openItems.value, props.name]
                    : openItems.value.filter((item) => item !== props.name);
            else
                openItems.value = !isOpen.value ? [props.name] : [];
            if (props.items?.length)
                open.value = true;
        };
        const close = () => {
            open.value = false;
        };
        onMounted(() => {
            autoAnimate(header.value);
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                ref_key: "header",
                ref: header,
                class: _normalizeClass(["menu-item", { active: _ctx.active }])
            }, [
                _createVNode(_unref(RouterLink), {
                    to: _ctx.to || '',
                    onClick: _cache[0] || (_cache[0] = ($event) => (toggle()))
                }, {
                    default: _withCtx(() => [
                        (!_ctx.minimized)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                                (_ctx.active)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_2))
                                    : _createCommentVNode("", true),
                                _createVNode(UiIcon, {
                                    icon: _ctx.icon,
                                    size: 24
                                }, null, 8, ["icon"]),
                                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.name), 1),
                                _createElementVNode("span", null, [
                                    (_ctx.items)
                                        ? (_openBlock(), _createBlock(UiIcon, {
                                            key: 0,
                                            icon: "chevron-down",
                                            size: 24,
                                            class: _normalizeClass(["transition-all duration-300", { 'rotate-180': isOpen.value }])
                                        }, null, 8, ["class"]))
                                        : _createCommentVNode("", true)
                                ])
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                (_ctx.active)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_5))
                                    : _createCommentVNode("", true),
                                _createVNode(UiIcon, {
                                    icon: _ctx.icon,
                                    size: 24
                                }, null, 8, ["icon"])
                            ]))
                    ]),
                    _: 1
                }, 8, ["to"]),
                (!_ctx.minimized && isOpen.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _renderSlot(_ctx.$slots, "items")
                    ]))
                    : _createCommentVNode("", true),
                (_ctx.minimized)
                    ? (_openBlock(), _createBlock(_Teleport, {
                        key: 1,
                        to: "body"
                    }, [
                        _createVNode(_unref(OnClickOutside), {
                            onTrigger: _cache[1] || (_cache[1] = ($event) => (close()))
                        }, {
                            default: _withCtx(() => [
                                _createVNode(_Transition, {
                                    mode: "out-in",
                                    "enter-active-class": "transition ease-in-out duration-[.5s] transform",
                                    "enter-from-class": "opacity-0",
                                    "leave-active-class": "transition ease-in-out duration-[.5s] transform",
                                    "leave-to-class": "opacity-0"
                                }, {
                                    default: _withCtx(() => [
                                        (open.value)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                                _renderSlot(_ctx.$slots, "compactItems", { close: close })
                                            ]))
                                            : _createCommentVNode("", true)
                                    ]),
                                    _: 3
                                })
                            ]),
                            _: 3
                        })
                    ]))
                    : _createCommentVNode("", true)
            ], 2));
        };
    }
});
