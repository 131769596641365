import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, unref as _unref, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "flex flex-col" };
const _hoisted_2 = {
    key: 0,
    class: "flex max-w-6xl flex-col-reverse justify-center lg:flex-row"
};
const _hoisted_3 = { class: "py-4" };
import { computed } from 'vue';
import { useSystemStore, useAuthStore, useGrupoDePermissoes } from '@/stores';
import CustomSvg from '@/components/ui/customSvg.vue';
import SplashImg from '@/assets/art/accounts.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'NavContas',
    setup(__props) {
        const authStore = useAuthStore();
        const grupoPermissoesStore = useGrupoDePermissoes();
        const systemStore = useSystemStore();
        const imgColor = computed(() => systemStore.getConfig.colors.images);
        const opcoes = computed(() => {
            let result = [];
            if (authStore.inAdminMode) {
                result.push({
                    nome: 'Empresas',
                    icon: {
                        name: 'person_add',
                        opticalSize: 48
                    },
                    to: '/conta/pessoa'
                }, {
                    nome: 'Contratos',
                    icon: {
                        name: 'edit_note',
                        weight: 300
                    },
                    to: '/conta/contrato'
                });
            }
            let navMenu = [
                {
                    nome: 'Meu Contrato',
                    icon: {
                        name: 'edit_document',
                        weight: 300
                    },
                    to: '/conta/meu-contrato'
                },
                {
                    nome: 'Unidades',
                    nameRoute: 'Unidades de Negócio',
                    icon: {
                        name: 'location_automation',
                        weight: 300
                    },
                    to: '/conta/unidade'
                },
                {
                    nome: 'Departamentos',
                    icon: {
                        name: 'workspaces',
                        weight: 400
                    },
                    to: '/conta/departamento'
                },
                {
                    nome: 'Cargos',
                    icon: {
                        name: 'badge',
                        weight: 300
                    },
                    to: '/conta/cargo'
                },
                {
                    nome: 'Usuários',
                    icon: {
                        name: 'supervisor_account',
                        weight: 400
                    },
                    to: '/conta/usuario'
                }
            ].filter((el) => grupoPermissoesStore.canUserAccess((el.nameRoute || el.nome), 'Contas'));
            result = [...result, ...navMenu];
            return result;
        });
        return (_ctx, _cache) => {
            const _component_pe_tabs = _resolveComponent("pe-tabs");
            const _component_router_view = _resolveComponent("router-view");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_pe_tabs, { itens: opcoes.value }, null, 8, ["itens"]),
                (_ctx.$route.path === '/conta')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createVNode(CustomSvg, { class: "grow text-center" }, {
                            default: _withCtx(() => [
                                _createVNode(_Transition, {
                                    appear: "",
                                    "enter-active-class": "transition ease-in-out delay-[.1s] duration-[.8s] transform",
                                    "enter-from-class": "opacity-0 -translate-y-8"
                                }, {
                                    default: _withCtx(() => [
                                        _createVNode(SplashImg, { class: "max-w-2xl" })
                                    ]),
                                    _: 1
                                })
                            ]),
                            _: 1
                        }),
                        _createElementVNode("div", _hoisted_3, [
                            _createVNode(_Transition, {
                                appear: "",
                                "enter-active-class": "transition ease-in-out delay-[.1s] duration-[.8s] transform",
                                "enter-from-class": "opacity-0 translate-x-8"
                            }, {
                                default: _withCtx(() => [
                                    _createElementVNode("div", {
                                        class: "mx-auto block w-80 rounded-3xl p-8 text-white lg:h-80",
                                        style: _normalizeStyle({
                                            backgroundColor: (!imgColor.value.primary.useDark || _unref(systemStore).isDark) ? imgColor.value.primary.light : imgColor.value.primary.dark
                                        })
                                    }, _cache[0] || (_cache[0] = [
                                        _createElementVNode("div", { class: "mb-4 text-xl lg:text-3xl" }, " Gerencie seu contrato, crie unidades, usuários e realize manutenções em cadastros relacionados. ", -1)
                                    ]), 4)
                                ]),
                                _: 1
                            })
                        ])
                    ]))
                    : (_openBlock(), _createBlock(_component_router_view, {
                        key: 1,
                        class: "flex w-full grow flex-col"
                    }))
            ]));
        };
    }
});
