import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, unref as _unref, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = ["for"];
const _hoisted_2 = {
    key: 0,
    class: "text-red-600"
};
const _hoisted_3 = {
    key: 0,
    class: "mt-1 text-xs text-[#FF4980]"
};
import { inject, provide } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'formControl',
    props: {
        label: {},
        prop: {},
        required: { type: Boolean }
    },
    setup(__props) {
        const props = __props;
        const validation = inject('validation', {});
        provide('fc-prop', props.prop);
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", null, [
                _renderSlot(_ctx.$slots, "title", {}, () => [
                    (_ctx.label)
                        ? (_openBlock(), _createElementBlock("label", {
                            key: 0,
                            for: _ctx.prop,
                            class: "mb-2 inline-block"
                        }, [
                            _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
                            (_ctx.required)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
                                : _createCommentVNode("", true)
                        ], 8, _hoisted_1))
                        : _createCommentVNode("", true)
                ]),
                _renderSlot(_ctx.$slots, "default"),
                _createVNode(_Transition, { name: "slide" }, {
                    default: _withCtx(() => [
                        (_unref(validation)[_ctx.prop]?.$messages[0])
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_unref(validation)[_ctx.prop]?.$messages[0]), 1))
                            : _createCommentVNode("", true)
                    ]),
                    _: 1
                })
            ]));
        };
    }
});
