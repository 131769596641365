import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex flex-wrap justify-center gap-1 py-4" };
const _hoisted_2 = ["href"];
const _hoisted_3 = ["href"];
import { computed, ref } from 'vue';
import { duration, formatter, safeCopy, dataURLtoFile } from '@/common/util';
import { useCertificadosUsuariosStore } from '@/stores';
import UiButton from '@/components/ui/button.vue';
import UiIcon from '@/components/ui/icon.vue';
import prevCanvas from '@/views/certificado/components/canvas.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PePrevCertificate',
    props: {
        headerTitle: {},
        certificateId: {},
        userId: {},
        type: {},
        idCourseFormation: {}
    },
    setup(__props, { expose: __expose }) {
        const certificadosUsuariosStore = useCertificadosUsuariosStore();
        const { plantar } = window.configuration || { plantar: {} };
        const defaultPrevCertificate = {
            tags: {},
            json: {}
        };
        const prevCertificate = ref({ ...safeCopy(defaultPrevCertificate) });
        const canvasRef = ref();
        const isLoading = ref(true);
        const certificateUser = ref();
        const props = __props;
        const modal = ref();
        const open = async (data) => {
            if (!data)
                return;
            isLoading.value = true;
            prevCertificate.value.json = typeof data.jsonCertificado === 'string' ? JSON.parse(data.jsonCertificado) : data.jsonCertificado;
            prevCertificate.value.tags = {
                name: data?.nomeUsuario,
                cpf: formatter.cpfCnpj(data.cpf),
                title: data?.title || null,
                date: data?.dataEmissao
                    ? new Date(data.dataEmissao).toLocaleDateString('pt-BR', { year: 'numeric', month: 'long', day: 'numeric' })
                    : null,
                time: data?.cargaHoraria ? duration(data.cargaHoraria) : null,
                cargo: data?.cargo
            };
            certificateUser.value = {
                title: data?.title || null,
                issueDate: new Date(data.dataEmissao)
            };
            await modal.value?.open();
            setTimeout(() => {
                if (!canvasRef.value) {
                    isLoading.value = false;
                    return;
                }
                // @FIXME: Foi feito um ajuste para corrigir o erro ao gerar o certificado, mas a implementação original não é boa
                // @TODO: Regatorar isso, não faz sentido sempre enviar uma imagem pro servidor
                // Solução: O endpoint que traz a extrutura do certificado, trazer também a imagem se essa já existir para o usuário atual
                // OBSEVAÇÃO: Auditores, esses comentários não são exibidos para o usuário final em produção
                const dataURL = canvasRef.value?.getElementsByTagName('canvas')[0].toDataURL();
                const file = dataURLtoFile(dataURL, 'certificado.png');
                certificadosUsuariosStore.create(props.certificateId, props.userId, props.type, props.idCourseFormation, file).then((res) => {
                    certificateUser.value.credential = res.credencial;
                }).finally(() => {
                    isLoading.value = false;
                });
            }, 200);
        };
        const saveImage = (imgName) => {
            const dataURL = canvasRef.value?.getElementsByTagName('canvas')[0].toDataURL();
            var link = document.createElement('a');
            link.download = imgName || 'certificado.png';
            link.href = dataURL;
            link.click();
        };
        const share = computed(() => {
            const linkedInUrlBase = window.configuration.linkedIn.host;
            const plantarUrlBase = [plantar.host, plantar.pathV1].join('/');
            return `${linkedInUrlBase}/shareArticle/?mini=true&url=${plantarUrlBase}/certificadousuario/${certificateUser.value.credential}/public`;
        });
        const addToLinkedin = computed(() => {
            const issueMonth = certificateUser.value.issueDate.getMonth() + 1;
            const issueYear = certificateUser.value.issueDate.getFullYear();
            const linkedInUrlBase = window.configuration.linkedIn.host;
            const plantarUrlBase = [plantar.host, plantar.pathV1].join('/');
            const certificateUrl = `${plantarUrlBase}/certificadousuario/${certificateUser.value.credential}/public`;
            return `${linkedInUrlBase}/profile/add?startTask=${certificateUser.value.title}&name=${certificateUser.value.title}&issueYear=${issueYear}&issueMonth=${issueMonth}&certUrl=${certificateUrl}&certId=${certificateUser.value.credential}`;
        });
        __expose({
            open
        });
        return (_ctx, _cache) => {
            const _component_pe_tooltip = _resolveComponent("pe-tooltip");
            const _component_pe_modal = _resolveComponent("pe-modal");
            return (_openBlock(), _createBlock(_component_pe_modal, {
                ref_key: "modal",
                ref: modal,
                header: _ctx.headerTitle || _ctx.$t('modals.previewCertificate'),
                width: "890px",
                "close-on-esc": ""
            }, {
                footer: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                        _createVNode(UiButton, {
                            type: "primary",
                            class: "p-2 uppercase",
                            onClick: _cache[1] || (_cache[1] = () => saveImage())
                        }, {
                            default: _withCtx(() => [
                                _createVNode(UiIcon, { icon: "download" })
                            ]),
                            _: 1
                        }),
                        (!isLoading.value && certificateUser.value.credential)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createElementVNode("a", {
                                    href: share.value,
                                    target: "_blank",
                                    class: _normalizeClass({ 'pointer-events-none': !certificateUser.value.credential })
                                }, [
                                    _createVNode(UiButton, {
                                        type: "primary",
                                        outline: "",
                                        class: "p-2 uppercase"
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(UiIcon, {
                                                icon: "brand-linkedin",
                                                size: "22px"
                                            }),
                                            _createTextVNode(" " + _toDisplayString(_ctx.$t('labels.shareLinkedin')), 1)
                                        ]),
                                        _: 1
                                    })
                                ], 10, _hoisted_2),
                                _createVNode(_component_pe_tooltip, {
                                    text: _ctx.$t('tooltips.certificates.linkedin'),
                                    icon: "",
                                    class: "mr-4 mt-2.5 text-green-500"
                                }, null, 8, ["text"]),
                                _createElementVNode("a", {
                                    href: addToLinkedin.value,
                                    target: "_blank",
                                    class: _normalizeClass({ 'pointer-events-none': !certificateUser.value.credential })
                                }, [
                                    _createVNode(UiButton, {
                                        type: "primary",
                                        outline: "",
                                        class: "p-2 uppercase"
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(UiIcon, {
                                                icon: "brand-linkedin",
                                                size: "22px"
                                            }),
                                            _createTextVNode(" " + _toDisplayString(_ctx.$t('labels.linkLinkedin')), 1)
                                        ]),
                                        _: 1
                                    })
                                ], 10, _hoisted_3),
                                _createVNode(_component_pe_tooltip, {
                                    text: _ctx.$t('tooltips.certificates.profile'),
                                    icon: "",
                                    class: "mr-4 mt-2.5 text-green-500"
                                }, null, 8, ["text"])
                            ], 64))
                            : _createCommentVNode("", true)
                    ])
                ]),
                default: _withCtx(() => [
                    _createElementVNode("div", {
                        class: "py-4",
                        ref_key: "canvasRef",
                        ref: canvasRef
                    }, [
                        (modal.value?.isOpen)
                            ? (_openBlock(), _createBlock(prevCanvas, {
                                key: 0,
                                modelValue: prevCertificate.value.json,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((prevCertificate.value.json) = $event)),
                                tags: prevCertificate.value.tags,
                                preview: ""
                            }, null, 8, ["modelValue", "tags"]))
                            : _createCommentVNode("", true)
                    ], 512)
                ]),
                _: 1
            }, 8, ["header"]));
        };
    }
});
