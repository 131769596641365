import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { computed } from 'vue';
import { useSystemStore } from '@/stores';
export default /*@__PURE__*/ _defineComponent({
    __name: 'customSvg',
    props: {
        primary: {},
        secondary: {},
        primaryDark: {},
        secondaryDark: {},
        primaryUseDark: { type: Boolean },
        secondaryUseDark: { type: Boolean },
        useSecondary: { type: Boolean }
    },
    setup(__props) {
        const system = useSystemStore();
        const props = __props;
        const sysColors = computed(() => system.getConfig.colors.images);
        const primary = computed(() => (!system.isDark && (props.primaryUseDark === true || sysColors.value.primary.useDark))
            ? (props.primaryDark || sysColors.value.primary.dark)
            : (props.primary || sysColors.value.primary.light));
        const secondary = computed(() => (props.useSecondary === true || sysColors.value.secondary.useCustom)
            ? (!system.isDark && (props.secondaryUseDark === true || sysColors.value.secondary.useDark))
                ? (props.secondaryDark || sysColors.value.secondary.dark)
                : (props.secondary || sysColors.value.secondary.light)
            : sysColors.value.secondary.default);
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                style: _normalizeStyle({ '--img-cp': primary.value, '--img-cs': secondary.value })
            }, [
                _renderSlot(_ctx.$slots, "default")
            ], 4));
        };
    }
});
