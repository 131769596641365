import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, unref as _unref, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "flex flex-col h-full" };
const _hoisted_2 = {
    key: 0,
    class: "flex max-w-6xl flex-col-reverse justify-center lg:flex-row"
};
const _hoisted_3 = { class: "py-4" };
import { computed } from 'vue';
import { useSystemStore, useGrupoDePermissoes } from '@/stores';
import CustomSvg from '@/components/ui/customSvg.vue';
import SplashImg from '@/assets/art/management.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'NavGestao',
    setup(__props) {
        const grupoPermissoesStore = useGrupoDePermissoes();
        const systemStore = useSystemStore();
        const imgColor = computed(() => systemStore.getConfig.colors.images);
        const opcoes = computed(() => [
            {
                nome: 'Meu Progresso',
                icon: {
                    name: 'history',
                    opticalSize: 48
                },
                to: '/gestao/progresso'
            },
            {
                nome: 'Dashboard',
                icon: {
                    name: 'monitoring',
                    opticalSize: 48
                },
                to: '/gestao/dashboard'
            },
            {
                nome: 'Meu Painel',
                icon: {
                    name: 'donut_large',
                    opticalSize: 48
                },
                to: '/gestao/meu-painel'
            },
            {
                nome: 'Histórico de envios',
                icon: {
                    name: 'upload_file',
                    opticalSize: 48
                },
                to: '/gestao/historico-envios'
            },
            {
                nome: 'Histórico de importações',
                icon: {
                    name: 'download_file',
                    opticalSize: 48
                },
                to: '/gestao/importacoes'
            },
            {
                nome: 'Certificados Externos',
                icon: {
                    name: 'bookmark_add',
                    opticalSize: 48
                },
                to: '/gestao/certificados-externos'
            },
            {
                nome: 'BI',
                icon: {
                    name: 'dashboard',
                    opticalSize: 48
                },
                redirect: 'https://bi.plantareducacao.com.br/'
            }
        ].filter((el) => grupoPermissoesStore.canUserAccess(el.nome, 'Gestão')));
        return (_ctx, _cache) => {
            const _component_pe_tabs = _resolveComponent("pe-tabs");
            const _component_router_view = _resolveComponent("router-view");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_pe_tabs, { itens: opcoes.value }, null, 8, ["itens"]),
                (_ctx.$route.path === '/gestao')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createVNode(CustomSvg, { class: "grow text-center" }, {
                            default: _withCtx(() => [
                                _createVNode(_Transition, {
                                    appear: "",
                                    "enter-active-class": "transition ease-in-out delay-[.1s] duration-[.8s] transform",
                                    "enter-from-class": "opacity-0 scale-75"
                                }, {
                                    default: _withCtx(() => [
                                        _createVNode(SplashImg, { class: "max-w-2xl" })
                                    ]),
                                    _: 1
                                })
                            ]),
                            _: 1
                        }),
                        _createElementVNode("div", _hoisted_3, [
                            _createVNode(_Transition, {
                                appear: "",
                                "enter-active-class": "transition ease-in-out delay-[.1s] duration-[.8s] transform",
                                "enter-from-class": "opacity-0 translate-x-8"
                            }, {
                                default: _withCtx(() => [
                                    _createElementVNode("div", {
                                        class: "mx-auto block w-80 rounded-3xl p-8 text-white lg:h-80",
                                        style: _normalizeStyle({
                                            backgroundColor: (!imgColor.value.primary.useDark || _unref(systemStore).isDark) ? imgColor.value.primary.light : imgColor.value.primary.dark
                                        })
                                    }, _cache[0] || (_cache[0] = [
                                        _createElementVNode("div", { class: "mb-4 text-xl lg:text-3xl" }, " Gerencie suas atividades, progresso, reveja conteúdos, baixe seus certificados e muito mais... ", -1)
                                    ]), 4)
                                ]),
                                _: 1
                            })
                        ])
                    ]))
                    : (_openBlock(), _createBlock(_component_router_view, {
                        key: 1,
                        class: "flex w-full grow flex-col"
                    }))
            ]));
        };
    }
});
