import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { class: "mb-4 flex flex-wrap items-center" };
const _hoisted_2 = {
    key: 0,
    class: "font-semibold text-lg"
};
const _hoisted_3 = {
    key: 1,
    class: "font-semibold text-lg"
};
const _hoisted_4 = {
    key: 2,
    class: "mr-6"
};
const _hoisted_5 = {
    key: 1,
    class: "container w-auto h-auto flex flex-col gap-2 p-4 items-center justify-center"
};
const _hoisted_6 = {
    key: 2,
    class: "container"
};
import { ref, reactive, nextTick, watch } from 'vue';
import { VueFinalModal } from 'vue-final-modal';
import { useRoute } from 'vue-router';
import { useAuthStore, useSalaDeAulaStore, useSecoesStore } from '@/stores';
import midiasService from '@/services/midias.service';
import scormAcompanhamento from '@/services/scormAcompanhamento.service';
import UiIcon from '@/components/ui/icon.vue';
import UiAlert from '@/components/ui/alert.vue';
import UiButton from '@/components/ui/button.vue';
import Player from './player.vue';
import ReactButton from '@/views/learningCenter/classRoom/components/reactButton.vue';
export default /*@__PURE__*/ _defineComponent({
    ...{
        name: 'ModalPlantarPlayer'
    },
    __name: 'modal',
    props: {
        src: {},
        type: {},
        mediaId: {},
        progress: {},
        trackProgress: { type: Boolean },
        title: {},
        allowDownload: { type: Boolean },
        watermark: { type: Boolean },
        watermarkRules: {},
        objData: {}
    },
    emits: ['onClose', 'onRegisterProgress'],
    setup(__props, { expose: __expose, emit: __emit }) {
        const props = __props;
        const route = useRoute();
        const salaDeAulaStore = useSalaDeAulaStore();
        const emit = __emit;
        const defaultScormData = {
            id: '',
            userId: '',
            cursoId: '',
            formacaoId: '',
            midiaId: '',
            children: '',
            version: '',
            completionStatus: '',
            completionThreshold: '',
            credit: '',
            entry: '',
            exit: '',
            launchData: '',
            location: '',
            maxTimeAllowed: '',
            mode: '',
            progressMeasure: 0,
            scaledPassingScore: 0,
            scoreChildren: 0,
            scoreScaled: 0,
            scoreRaw: 0,
            scoreMin: 0,
            scoreMax: 0,
            sessionTime: '',
            successStatus: '',
            suspendData: '',
            timeLimitAction: '',
            totalTime: '',
            adlNavRequest: '',
            fullTime: '',
            suspendData1: '',
            scormScriptLink: ''
        };
        const downloadBtn = ref();
        const scormData = reactive({ ...defaultScormData });
        const listenOnMessage = ref(false);
        const pdfViewerRef = ref();
        const media = ref();
        const mediaInfo = ref();
        const status = ref('initial');
        const show = ref(false);
        const localProgress = ref(0);
        const captions = ref([]);
        const chapters = ref([]);
        watch(() => [pdfViewerRef.value?.page, pdfViewerRef.value?.pageCount], (nVal, oVal) => {
            if (!props.trackProgress)
                return;
            if (!oVal[1] && !!nVal[1] && nVal[1] > 1 && props.progress > 0 && props.progress < 100) {
                pdfViewerRef.value?.jumpToPercent(props.progress);
            }
            if (pdfViewerRef.value?.pageCount) {
                const percent = Math.round((pdfViewerRef.value.page / pdfViewerRef.value.pageCount) * 100);
                percent > localProgress.value && registerProgress(percent);
            }
        });
        const downloadFile = () => {
            downloadBtn.value.href = props.src;
            downloadBtn.value.click();
        };
        const getInfoVideo = async () => {
            if (media.value?.videoId) {
                const info = await salaDeAulaStore.getVideoInfo(media.value.videoId, { ambiente: 'Consumo' });
                chapters.value = info?.chapters || [];
                captions.value = info?.captions || [];
            }
        };
        const open = async () => {
            show.value = true;
            nextTick(async () => {
                if (['video', 'audio'].includes(props.type)) {
                    await loadInfoMedia();
                    getInfoVideo();
                }
                else if (props.type === 'scorm' && props.trackProgress) {
                    window.addEventListener('message', onMessage, false);
                    await loadProgressScorm();
                    props?.src?.includes('scormcontent') && props.trackProgress && registerProgress(100);
                }
                else if (['img', 'document', 'documento'].includes(props.type) && props.trackProgress)
                    registerProgress(100);
                localProgress.value = props.progress;
                loadMediasInfo();
            });
        };
        const close = () => {
            props.type === 'scorm' && props.trackProgress && window.removeEventListener('message', onMessage, false);
            Object.assign(scormData, { ...defaultScormData });
            localProgress.value = 0;
            show.value = false;
            status.value = 'initial';
            emit('onClose');
        };
        const registerProgress = (progress) => {
            event?.stopPropagation();
            const fmProgress = Math.round(progress);
            if ((fmProgress < localProgress.value) || fmProgress === 0)
                return;
            localProgress.value = fmProgress;
            if (!props.trackProgress || props.progress === 100)
                return;
            midiasService.registerProgress(props.mediaId, fmProgress, {
                formationId: null,
                courseId: null,
                isClassroom: false
            }).then(() => emit('onRegisterProgress', { progress: fmProgress, id: props.mediaId }));
        };
        const loadScormPlayer = async () => {
            scormData.userId = useAuthStore().persistentInfo.id;
            if (props.trackProgress) {
                await salaDeAulaStore.getInfoScorm(props.mediaId, {
                    cursoId: salaDeAulaStore?.type === 'course' ? salaDeAulaStore.info.id : null,
                    formacaoId: salaDeAulaStore?.type === 'formation' ? salaDeAulaStore.info.id : null
                });
                Object.assign(scormData, { ...salaDeAulaStore.scormProgress });
            }
            setTimeout(() => {
                sendToScormPlayer('start', salaDeAulaStore.scormProgress.suspendData);
                listenOnMessage.value = true;
            }, 1000);
        };
        const sendToScormPlayer = (func, data) => {
            nextTick(() => {
                const scormFrame = document.getElementById('player-frame')?.contentWindow;
                if (scormFrame)
                    scormFrame.postMessage({ func, data: data || null }, '*');
            });
        };
        // Transforma o nome das propriedades scorm em camelCase
        // cmi.core.lesson_status_exit => lessonStatusExit
        const transformCamelCaseScorm = (obj) => {
            return obj
                .split('.')
                .at(-1)
                .split('_')
                .map((v, i) => (i === 0 ? v : v[0].toUpperCase() + v.substring(1)))
                .join('');
        };
        const onMessage = (event) => {
            const { data: msgData } = event;
            if (!msgData || !listenOnMessage.value)
                return;
            const { func, data } = msgData;
            if (func === 'LMSSetValue') {
                const prop = transformCamelCaseScorm(data.item);
                scormData[prop] = data.value;
                if (prop === 'lessonStatus' && ['completed', 'passed'].includes(data.value))
                    registerProgress(100);
            }
            if (func === 'LMSCommit') {
                scormAcompanhamento.put('', scormData);
            }
            else if (func === 'riseCommit') {
                scormData.suspendData = data.suspend_data;
                scormAcompanhamento.put('', {
                    ...data,
                    userId: scormData.userId,
                    id: scormData.id
                });
                if (['completed', 'passed'].includes(data.lesson_status))
                    registerProgress(100);
            }
        };
        const loadInfoMedia = async () => {
            if (!props.mediaId)
                return;
            status.value = 'loading';
            try {
                const { data } = await midiasService.get(props.mediaId);
                media.value = data;
                status.value = 'finish';
            }
            catch {
                status.value = 'error';
            }
        };
        const loadProgressScorm = async () => {
            if (!props.mediaId)
                return;
            status.value = 'loading';
            try {
                const { data } = await scormAcompanhamento.getProgressScorm(props.mediaId);
                if (!data) {
                    const { data: progressCreated, success } = await scormAcompanhamento.post('', { midiaId: props.mediaId });
                    if (success) {
                        Object.assign(scormData, progressCreated);
                    }
                }
                else
                    Object.assign(scormData, data);
                status.value = 'finish';
            }
            catch {
                status.value = 'error';
            }
        };
        const onReacted = (res) => {
            Object.assign(mediaInfo.value, {
                ...mediaInfo.value,
                reacao: res?.reacao,
                totalReacoes: res?.totalReacoes
            });
            useSecoesStore().updateSectionItem(mediaInfo.value?.id, {
                totalReacao: res?.totalReacoes,
                reacao: res?.reacao
            });
        };
        const loadMediasInfo = async () => {
            if (!props.mediaId || route.name == 'files')
                return;
            if (['video', 'audio'].includes(props.type))
                return mediaInfo.value = media.value;
            const { data } = await midiasService.get(props.mediaId);
            mediaInfo.value = data;
        };
        __expose({
            open,
            close
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(_unref(VueFinalModal), {
                modelValue: show.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((show).value = $event)),
                classes: "overflow-y-auto bg-black/50",
                "content-class": "flex flex-wrap justify-center box-border items-center w-full h-full box-border overflow-y-auto p-4",
                "esc-to-close": "",
                "click-to-close": "",
                onClosed: close
            }, {
                default: _withCtx(() => [
                    (['initial', 'finish'].includes(status.value))
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass([{
                                    'w-full max-w-[1240px] min-h-[680px]': _ctx.type !== 'img',
                                    'w-auto': _ctx.type === 'img'
                                }, "container"])
                        }, [
                            _createElementVNode("div", _hoisted_1, [
                                (_ctx.title)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.title), 1))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('labels.preview')) + " " + _toDisplayString({ link: _ctx.$t('labels.link'), video: _ctx.$t('labels.video'), pdf: _ctx.$t('labels.pdf'), img: _ctx.$t('labels.image'), scorm: _ctx.$t('labels.link') }[_ctx.type] || _ctx.$t('labels.content')), 1)),
                                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "grow" }, null, -1)),
                                (_ctx.allowDownload && _ctx.type.includes('document'))
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                        _createVNode(UiButton, {
                                            type: "primary",
                                            outline: "",
                                            icon: "download",
                                            "icon-size": "18px",
                                            class: "p-1.5 text-sm outline-[1px]",
                                            onClick: downloadFile
                                        }, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.$t('button.download')), 1)
                                            ]),
                                            _: 1
                                        })
                                    ]))
                                    : _createCommentVNode("", true),
                                (_unref(route).name !== 'files')
                                    ? (_openBlock(), _createBlock(ReactButton, {
                                        key: 3,
                                        class: "mx-2",
                                        typeContent: "midia",
                                        contentId: mediaInfo.value?.id,
                                        reaction: mediaInfo.value?.reacao,
                                        qtd: mediaInfo.value?.totalReacoes,
                                        onOnSuccess: onReacted
                                    }, null, 8, ["contentId", "reaction", "qtd"]))
                                    : _createCommentVNode("", true),
                                _createVNode(UiButton, {
                                    type: "ghost",
                                    round: "",
                                    icon: "x",
                                    class: "p-2",
                                    onClick: close
                                })
                            ]),
                            (_ctx.src)
                                ? (_openBlock(), _createBlock(Player, {
                                    key: 0,
                                    source: media.value?.hlsPlaylistURL ? `${media.value?.pullzone}/${media.value?.videoId}/playlist.m3u8` : _ctx.src,
                                    type: _ctx.type,
                                    mediaId: _ctx.mediaId,
                                    pullzone: media.value?.pullzone,
                                    progress: _ctx.progress,
                                    "video-id": media.value?.videoId,
                                    chapters: chapters.value,
                                    captions: captions.value,
                                    "allow-download": _ctx.allowDownload,
                                    watermark: _ctx.objData?.watermarkRules.enabled,
                                    "watermark-rules": _ctx.objData?.watermarkRules,
                                    disableFastForward: media.value?.bloquearAvanco,
                                    onOnLoadFrame: loadScormPlayer,
                                    onOnUpdateProgress: registerProgress,
                                    class: "w-full h-auto aspect-video"
                                }, null, 8, ["source", "type", "mediaId", "pullzone", "progress", "video-id", "chapters", "captions", "allow-download", "watermark", "watermark-rules", "disableFastForward"]))
                                : _createCommentVNode("", true)
                        ], 2))
                        : _createCommentVNode("", true),
                    (status.value === 'loading')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _createVNode(UiIcon, {
                                icon: "loader",
                                class: "animate-spin mb-2"
                            }),
                            _createElementVNode("div", null, _toDisplayString(_ctx.$t('feedback.loading')), 1)
                        ]))
                        : _createCommentVNode("", true),
                    (status.value === 'error')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            _createVNode(UiAlert, {
                                type: "danger",
                                title: _ctx.$t('alerts.errorMediaTitle'),
                                text: _ctx.$t('alerts.errorMediaMessage'),
                                class: "mb-6"
                            }, null, 8, ["title", "text"]),
                            _createVNode(UiButton, {
                                type: "primary",
                                outline: "",
                                class: "px-4 py-2 text-sm uppercase outline-[1px]",
                                onClick: close
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('button.close')), 1)
                                ]),
                                _: 1
                            })
                        ]))
                        : _createCommentVNode("", true),
                    _createElementVNode("a", {
                        ref_key: "downloadBtn",
                        ref: downloadBtn,
                        href: "",
                        download: ""
                    }, null, 512)
                ]),
                _: 1
            }, 8, ["modelValue"]));
        };
    }
});
